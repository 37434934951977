@import '../../scss/base.scss';

.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: $modalOverlayBg;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
    overflow: scroll;

    @include mobile {
        background-color: unset;
        -webkit-overflow-scrolling: touch;
    }
}

.modal {
    box-sizing: border-box;
    position: absolute;
    width: 430px;
    top: 40px;
    bottom: 40px;
    overflow: scroll;
    padding: 40px;
    background: #FFFFFF;
    /* bubble shadow */
    box-shadow: 4px 4px 10px rgba(57, 57, 57, 0.1);
    border-radius: 10px;
    -webkit-font-smoothing: unset;

    @include mobile {
        top: 0;
        bottom: 0;
        width: 100%;
        padding: 20px;
    }

    .closeButton {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        img {
            width: 30px;
            height: 30px;
        }
    }

    .title h2 {
        @include title;
        margin: 0;
        width: 80%;
        @include mobile{
            margin-top: 20px;
            width: 70%
        }
    }

    .title p {
        @include text;
        margin: 0;
        padding-top: 10px;
       
    }

    .form {
        margin-top: 10px;

        h4 {
            margin: 15px 0;
            font-family: Circular Std bld, Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 23px;
            /* v2/black */
            color: $baseBlack;
            @include mobile{
                font-size: 16px;
            }

            span {
                font-family: Circular Std Book, Arial, Helvetica, sans-serif;
                font-weight: normal;
                -webkit-font-smoothing: auto;
            }
        }

        input[type="text"],
        input[type="tel"],
        input[type="number"],
        input[type="email"] {
            box-sizing: border-box;
            height: 50px;
            width: 100%;
            padding: 13px 30px;
            border: none;
            outline: none;
            /* v2/QUIZ/quiz neutral */
            background: $baseInputEdit;
            border-radius: 60px;
        }

        textarea {
            width: 100%;
            box-sizing: border-box;
            height: 110px;
            padding: 15px 23px;
            resize: none;
            border: none;
            outline: none;
            border-radius: 10px;
            background: $baseInputEdit;
            text-align: left;
        }

        hr {
            opacity: 0.6;
            /* Black */
            border: 1px solid $baseBlack;
        }

        textarea,
        input,
        input::placeholder {
            font-family: Circular Std Book, Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 23px;
            /* v2/black */
            color: #393939;
            @include mobile{
                font-size: 14px;
            }

        }

        input::placeholder {
            opacity: 0.6;
        }

        button {
            cursor: pointer;
            width: 100%;
            height: 50px;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            outline: none;
            /* BG/White */
            color: #FFFFFF;
            /* Black */
            background: $baseGreyColor;
            // opacity: .6;
            border-radius: 60px;
            font-family: Circular Std bld, Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 23px;

            img {
                margin-right: 5px;
            }
        }

        .price {
            display: flex;

            input {
                margin-right: 10px;
            }

            >div {
                width: 60%;
            }
        }

        .commentsLabel {
            display: flex;
            justify-content: space-between;
            width: 100%;

            p {
                font-family: Circular Std Book, Arial, Helvetica, sans-serif;
            }
        }

        .phone {
            position: relative;
        }

    }

}

.modal:focus {
    outline: none;
}
.modal::-webkit-scrollbar{
    width: 10px;
 }