@import "../../../scss/base.scss";

.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: $modalOverlayBg;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;

    @include mobile {
        width: 100vw;
        height: 100vh;
    }
}

.modal {
    position: relative;
    width: 100%;
    max-width: 540px;
    border-radius: 8px;
    box-shadow: 0 3px 26px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-height: 100vh;

    @include mobile {
        width: 90vw;
      
    }


    .closeButton {
        position: absolute;
        right: 25px;
        top: 25px;
        cursor: pointer;

        img {
            width: 24px;
            height: 24px;
        }
    }

    .content {
        flex: 1;
        width: auto;
        padding: 50px 0;
        overflow-y: auto;
        text-align: center;
    }
}
