@import "../../scss/base.scss";

.section {
    box-sizing: border-box;
    width: 100%;
    max-width: calc(1074px + 60px);
    padding: 0 30px;
    margin: 0 auto;
    margin-top: 20px;
    @include mobile {
      padding: 0 0 0 15px;
      margin-top: 0;
    }
    > div {
      border-top: unset;
    }
  }

  .sectionHeight {
    > div {
      margin: 15px auto;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
    margin: 70px 0;
    @include tablet{
      margin: 40px 0;
    }
  }