@import '../../../scss/base.scss';

.notificationCard {
  background: $secondaryLightGreen;
  margin: 0;
 
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;

  .message,
  .notificationButton {
    max-width: 80%;
    font-family: $mawoo-font-medium;
    letter-spacing: 0;
  }

  .message {
    margin: 0;
    margin-bottom: 16px;
    text-align: center;
    /* H3 */
    font-size: 20px;
    color: $baseBlack;
    @include mobile {
      font-size: 14px;
    }
  }
  .notificationButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $baseRedColor;
    padding: 8px 0px;
    border-radius: 75px;
    width: 100%;
    height: 50px;
    border: none;
    cursor: pointer;
    color: white;
    /* H4 */
    font-size: 18px;
    line-height: normal;
    @include mobile {
      font-size: 14px;
    }
  }
  .notificationButton:hover {
    background: $baseDarkRed;
  }
  .notificationImg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .thankYou {
      font-family: $mawoo-font-bold;
      font-size: 20px;
      padding: 10px 0;
      @include mobile {
        font-size: 16px;
      }
    }
    .text {
      font-family: $mawoo-font-book;
      text-align: center;
      font-size: 16px;
      @include mobile {
        font-size: 12px;
      }
    }
    .thankYou,
    .text {
      color: $baseBlack;
      margin: 0;
      max-width: 80%;
    }
  }
  .heartImg{
    height: 70px;
    @include mobile{
      height: 40px;
    }
  }
}

// Notification Layout

.pair {
  flex-direction: row;
  grid-column-start: 1;
  grid-column-end: 5;
  max-height: 104px;
  justify-content: space-around;

  .notificationButton {
    width: 20%;
    @include mobile {
      width: 50%;
    }
  }

  .message {
    text-align: left;
    margin-bottom: 0;
    @include mobile {
      margin-bottom: 16px;
      max-width: 80%;
      text-align: center;
      font-size: 16px;
    }
  }

  @include mobile {
    flex-direction: column;
    grid-column-start: 1;
    grid-column-end: 3;
    justify-content: center;
  }
}

.pair {
  width: 100%;
  @include mobile {
    max-height: 140px;
  }
}

.noPair{
  width: min-content;
  max-width: 255px;
  @include mobile{
    max-width: 165px;
  }
}