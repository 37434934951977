@import "../../scss/base.scss";

.aboutContainer {
  box-sizing: border-box;
  max-width: calc(1085px + 60px);
  padding: 50px 30px 0 30px;
  margin: 0 auto;
  @include tablet {
    max-width: unset;
    padding: unset;
    margin: 30px 0;
  }
  .aboutCategoryBox {
    box-sizing: border-box;
    display: flex;
    @include tablet {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
    }

    .aboutData {
      box-sizing: border-box;
      margin-top: 130px;
      padding: 40px;
      width: 521px;
      position: relative;
      z-index: 2;
      /* new light green */
      background: #c6f6e3;
      border-radius: 10px;
      /* v2/black */
      color: #393939;
      @include tablet {
        margin-top: 130px;
      }

      @include mobile {
        width: 330px;
        margin-top: 220px;
        padding: 20px;
      }
      h2 {
        /* H2 */
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        font-size: 25px;
        line-height: 32px;
        margin: 0 auto;
        @include mobile {
          font-size: 20px;
          line-height: 25px;
        }
      }
      p {
        font-family: Circular Std book, Helvetica, Arial, sans-serif;
        font-size: 18px;
        line-height: 23px;
        margin: 0 auto;
        padding-top: 10px;
        @include mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
      a{
        font-family: Circular Std book, Helvetica, Arial, sans-serif;
        font-size: 18px;
        line-height: 23px;
        margin: 0 auto;
        color: #393939;
        padding-top: 10px;
        @include mobile {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .view {
        font-size: 14px;
        line-height: 23px;
        font-family: Circular Std Book;
        font-weight: bold;
        color: #393939;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 0;
       
        .arrowUp,
        .arrowDown {
          background-size: auto;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 10px;
          height: 10px;
          margin-left: 5px;

        }

        .arrowDown {
          background-image: url("/search/arrow-down-black.svg");
        }

        .arrowUp {
          background-image: url("/search/arrow-top-black.svg");
        }
      }
    }
    .aboutImageContainer {
      width: 660px;
      position: absolute;
      z-index: 1;
      right: 14%;
      @include tablet {
        right: unset;
        width: 100%;
      }
    }
    .aboutImage {
      width: 660px !important;
      height: 400px;
      border-radius: 10px;
      @include tablet {
        height: 286px;
        width: 100% !important;
        border-radius: unset;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }
    .aboutImageContainer:nth-child(2) {
      > div {
        > div {
          > span {
            background: white;
          }
        }
      }
    }
  }
}
.sizeContainer {
  box-sizing: border-box;
  max-width: calc(1085px + 60px);
  padding: 60px 30px 50px 30px;
  margin: 0 auto;
  @include mobile {
    padding: unset;
   
  }
  
  .title {
    margin: 0;
    @include mobile {
      padding: 0 23px;
      margin: 0 auto;
    }
    h2 {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 25px;
      line-height: 32px;
      /* v2/black */
      color: #393939;
      margin: 0;
      @include mobile {
        font-size: 20px;
        line-height: 25px;
        width: 75%;
      }
    }
  }
  .test {
    @media screen and (max-width: 900px) {
      overflow: scroll;
    }
    @include mobile {
      overflow: scroll;
    }
    .sizeButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      @media screen and (max-width: 900px) {
        width: 900px;
      }
      @include mobile {
        width: 550px;
        padding-left: 23px;
      }
      .sizeButton, .disabled {
        cursor: pointer;
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        /* v2/black */
        color: #393939;
        font-size: 20px;
        line-height: 25px;
        width: 202px;
        height: 60px;
        /* new light green */
        background: #c6f6e3;
        border-radius: 10px;
        border-color: unset;
        border-style: unset;
        @include tablet {
          width: 160px;
          font-size: 18px;
          line-height: 20px;
        }
        @include mobile {
          width: 106px;
          height: 40px;
          margin-top: 10px;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .selected,
      .sizeButton:hover {
        color: white;
        /* v2/green */
        background: #00ad66;
        /* Review card */
        box-shadow: 0px 2px 4px rgba(57, 57, 57, 0.25);
      }
      .disabled{
        color: #393939;
        background: rgba(57, 57, 57, 0.204);
      }
    }
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .test::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .example {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .sizeSelected {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    justify-content: center;
    @include mobile {
      display:block;
      margin-top: auto;
      >div{
        >div{
          >div:last-child {
            margin-right: 15px;
          }

          >div:first-child {
            margin-left: 15px;
          }
          >div{
           
            width: auto !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            
           
          }
        }
      }
    }
    .breed {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 180.5px !important;
      margin-top: 15px;
      @include tablet {
        width: 177px !important;
      }
      @include mobile {
        width: fit-content !important;
      }
      .profilePicture {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        border: 5px solid #a6e8cd;
      }
      .profilePicture:hover {
        border-color: #00ad66;
      }
      a {
        text-decoration: none;
        .breedName {
          font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
          margin: 10px 0 0;
          font-size: 18px;
          line-height: 23px;
          text-align: center;
          /* v2/black */
          color: #393939;
          @include mobile {
            font-size: 14px;
            line-height: 18px;
          }
        }
        .breedName:hover {
          color: #00ad66;
        }
      }
    }
  }
  .viewButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    margin-top: 21px;
    @include mobile{
      display:  none;
    }
    button {
      height: 50px;
      width: 250px;
      img {
        height: 15px !important;
        width: 12px !important;
      }
    }
  }
}

.section, .largeSection {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(1085px + 60px);
  padding: 0 30px;
  margin: 0 auto;
  margin-top: 60px;
  @include mobile {
    padding: 0 15px;
    margin-top: 40px;
  }
}
.largeSection{
  @include mobile{
    margin-top: 60px;
  }
}