@import '../../scss/base.scss';

.why {
  background: $secondaryLightGreen;
  display: flex;
  justify-content: center;
  padding: 60px 40px;

  @include mobile {
    padding: 40px 24px;
  }

  &_container {
    box-sizing: border-box;
    width: 100%;
    max-width: calc(1074px + 60px);
    display: flex;
    flex-direction: column;
    align-items: center;

    &_titles {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 680px;
    }
  }

  &_title,
  &_sub {
    margin: 0;
    font-weight: unset;
    color: $baseBlack;
    text-align: center;
  }

  &_title {
    font-family:
      Circular Std bld,
      Helvetica,
      Arial,
      sans-serif;
    font-size: 30px;

    @include mobile {
      font-size: 25px;
    }
  }

  &_sub {
    font-size: 20px;
    line-height: 23px;
    font-family:
      Circular Std Book,
      Helvetica,
      Arial,
      sans-serif;
    padding-top: 8px;
    opacity: 0.8;

    @include mobile {
      font-size: 18px;
    }
  }

  &_features {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mobile {
      margin: 24px 0;
      flex-direction: column;
      width: 100%;
    }
  }

  &_feature {
    box-sizing: border-box;
    max-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 29px 24px 29px;

    @include mobile {
      flex-direction: row;
      max-width: 100%;
      padding: 0 0 16px 0;
    }

    &_icon {
      box-sizing: border-box;
      background: white;
      border-radius: 50%;
      padding: 15px;
      max-width: 100px;
      max-height: 100px;

      &_img {
        height: 70px;
        width: 70px;
      }

      @include mobile {
        padding: 10px;
        max-width: 56px;
        max-height: 56px;

        &_img {
          height: 38px;
          width: 38px;
        }
      }
    }

    &_title {
      margin: 0;
      font-family:
        Circular Std bld,
        Helvetica,
        Arial,
        sans-serif;
      padding-top: 10px;
      font-size: 18px;
      font-weight: unset;
      text-align: center;
      color: $baseBlack;

      @include mobile {
        font-size: 16px;
        text-align: left;
        padding-left: 16px;
        padding-top: 0;
      }
    }
  }
}

.btn {
  border: 1px solid $baseBlack;
  padding: 16px 32px;
}
