@import "../../../scss/base.scss";

.article {
    box-sizing: border-box;
    width: 100%;
    max-width: calc(1085px + 60px);
    padding: 30px 30px 0;
    margin: 0 auto;
    margin-bottom: 80px;
    overflow: hidden;
    @include tablet{
        padding: 0 15px;
        margin-bottom: 50px;
    }
  &.faqs{
    padding: 0;
  }
    h2{
      color: #393939;
      font-family: Circular Std bld, Arial, Helvetica, sans-serif;
      font-size: 25px;
      line-height: 20px;
      font-weight: bold;
      @include mobile{
        font-size: 20px;
      }
    }
    .container{
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        border-top: 1px solid #393939;
      &.firstContainer{
        border-top: none;
      }
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            @include mobile{
              align-items: flex-start;
            }
    
            h3{
                margin: 0;
                color: #393939;
                font-family: Circular Std bld, Arial, Helvetica, sans-serif;
                font-size: 18px;
                line-height: 20px;
                font-weight: bold;
        
                @include tablet {
                    font-size: 16px;
                    line-height: 18px;
                    padding: 0;
                }
        
                @include mobile {
                  font-size: 16px;
                  line-height: 18px;
                  margin-top:0px;
                  padding: 0px; 
              
                }
                >a{
                  font-family:Circular Std Book;
                margin: 0 3px;
                color:#393939 !important;
                text-decoration-line: underline;
                text-underline-offset: 2px;
                }
            }
           .icon{ 
            display: block;
            // background-color: #f5f4fb;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35px;
            height: 35px;
            min-width: 35px;
            margin-left: 1rem;
            cursor: pointer;
            >img{
                width: 100%;
                height: 100%;
            }
           }
        }
            h3{
                color:#393939;
                font-weight: bold;
                font-family:Circular Std bld;
                margin: 0px;
                height: 5px;
                font-size: 16px;
                @include mobile{
                  font-size: 14px;
                }
                
              }

              .text {
                @include text;
                font-size: 16px;
                margin: 10px 0;
                width: 65%;
                @include mobile{
                  margin: 10px 0;
                font-size: 14px;
                width: 100%;
                }
                ul, ol{
                  margin:0;
                  padding-inline-start: 25px;
                  li{
                    @include text;
                    font-family: Circular Std Book, Helvetica, Arial, sans-serif;
                    font-size: 16px;
                    @include mobile{
                      font-size: 14px;
                    }
                  }
                }
            }
            ul, ol{
              margin:0;
              padding-inline-start: 25px;
              li{
                @include text;
                font-family: Circular Std Book, Helvetica, Arial, sans-serif;
                font-size: 16px;
                @include mobile{
                  font-size: 14px;
                }
              }
            }
            .link{
                font-family:Circular Std Book, Helvetica, Arial, sans-serif !important;
                margin: 0 3px;
                color:#393939 !important;
                text-decoration-line: underline;
                text-underline-offset: 2px;
              }
        }
    
    .gallery{
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     margin-top: 40px;
     @include mobile{
        flex-wrap: wrap;
        margin-top: 10px;
        >div{
            width: calc(100% / 2);
            >span{
                margin:5px !important
            };
        }
     }
    }
    }

    .article[id] {
      scroll-margin-top: 70px;
      @include mobile{
        scroll-margin-top: 50px;
      }
    }
