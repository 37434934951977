@import '../../../scss/base.scss';

.toast {
  box-sizing: border-box;
  width: 328px;
  min-height: auto;
  padding: 45px;
  z-index: 999999999;
  border-radius: 10px;
  cursor: pointer;

  @include mobile {
    width: 100%;
    min-height: 190px;
    border-radius: 20px 20px 0px 0px;
  }

  &.success {
    background: #00ad66;
  }

  &.error {
    background: #ff1e57;
  }

  .closeButton {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    right: 20px;
    background-image: url('/close-white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .title {
    font: 18px Circular Std Bld, Helvetica, Arial, sans-serif;
    line-height: 24px;
    text-align: left;
    color: #ffffff;

    @include mobile {
      font-size: 18px;
      line-height: 23px;
      text-align: center;
    }
  }

  .message {
    margin-top: 15px;
    margin-bottom: 15px;
    font: 18px Circular Std Book, Helvetica, Arial, sans-serif;
    line-height: 23px;
    color: #ffffff;

    @include mobile {
      margin-top: 10px;
      font-size: 16px;
      line-height: 18px;
      text-align: center;
    }

    span {
      font-weight: bold;
    }
  }

  button {
    width: 100%;
    margin-top: 60px;

    @include mobile {
      margin-top: 20px;
    }
  }
}
