@import '../../scss/base.scss';

//* -- Featured -- *//

.featuredContainer {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(1074px + 60px);
  padding: 0px 30px 0 30px;
  margin: 80px auto 0px;

  display: flex;
  flex-direction: column;
  @include mobile {
    margin: 0;
    padding: 0px 15px 0 15px;
  }
  hr {
    @include mobile {
      margin: 1rem 0;
      color: $baseBlack;
    }
  }

  .serviceBanner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
    margin-bottom: 30px;
    padding: 30px;
    /* new light green */
    background: $secondaryLightGreen;
    border-radius: 10px;
    @include mobile {
      margin-bottom: 15px;
    }
    p {
      margin: 0 auto;
      font-family: $mawoo-font-book;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      /* v2/black */
      color: $baseBlack;
      @include mobile {
        font-size: 12px;
        line-height: 15px;
      }
      strong {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 10px;
        @include mobile {
          font-size: 16px;
          line-height: 20px;
        }
      }
      a {
        color: $baseBlack;
        font-family: $mawoo-font-bold;
      }
    }

    @include mobile {
      height: 120px;
    }
  }
  .featuredTitle {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 18px;

    h2 {
      font-family: $mawoo-font-bold;
      font-size: 25px;
      line-height: 32px;
      color: $baseBlack;
      margin: 0px;
      @include mobile {
        margin: 10px 0 0;
        font-size: 18px;
        line-height: 23px;
      }
    }
    a {
      font-family: $mawoo-font-bold;
      font-size: 18px;
      line-height: 30px;
      /* v2/green */
      color: $baseGreen;
      @include mobile {
        font-size: 16px;
        line-height: 20px;
        width: 100px;
        text-align: right;
      }
    }
  }
}

.featuredGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @include mobile {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 30px;
  }
}

.featuredButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 21px 0;
  cursor: pointer;
  button {
    height: 50px;
    width: 200px;
    img {
      height: 15px !important;
      width: 12px !important;
    }
  }
}
