@import "../../scss/base.scss";

.section {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(1074px + 60px);
  padding: 0 30px;
  margin: 0 auto;
  margin-top: 60px;
  @include mobile {
    padding: 0 15px;
    margin-top: 40px;
  }
}