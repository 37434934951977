@import '../../scss/base.scss';

.nationWideContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
  height: 650px;
  /* Rectangle 1713 */
  margin-top: 40px;
  /* new beige */
  background: #fffaf3;

  @include mobile {
    height: 550px;
    margin-top: 30px;
  }

  .bulletsContainerMobile {
    display: none;
    @include tablet {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: calc(1074px + 60px);
      padding: 0 20px;
      margin-top: 10px;
      margin-bottom: 20px;
      position: relative;
    }

    .btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 8px;
      margin: 5px 0px;

      button {
        width: 130px;
        height: 35px;
        background-color: white;
        border-radius: 30px;
        border: 1px solid #00ad66;
        cursor: pointer;
        outline: none;
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        text-align: center;
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        color: #00ad66;
        padding: 5px 20px;
      }
      button:hover {
        border: 1px solid #008c54;
      }
    }
    .bulletsBox {
      box-sizing: border-box;
      height: auto;
      width: 30%;
      padding: 30px 10px;
      background: #ffffff;
      box-shadow: 0px 2px 10px #e3e2dc;
      border-radius: 8px;
      @include mobile{
        width: 32%;
      }

      .bulletFact {
        display: flex;
        align-items: flex-start;
        width: 80%;
        padding: 0 30px;
        img {
          margin-right: 5px;
          width: 32px;
          height: 32px;
        }
        p {
          color: #393939;
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 16px;
          line-height: 20px;
          height: auto;
          text-align: center;
          width: 250px;
          margin: 0;
          margin-bottom: 30px;
          strong {
            font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
          }
        }
      }
    }
  }

  .bulletsContainerDesktop {
    box-sizing: border-box;
    display: flex;
    max-width: calc(1074px + 60px);
    padding: 0 30px;
    margin-top: 10px;
    @include tablet {
      display: none;
    }
    .bulletsBox {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 150px;
      background: #ffffff;
      box-shadow: 0px 2px 10px #e3e2dc;
      border-radius: 8px;
      padding: 30px 20px 20px 50px;

      .travel {
        height: 70px;
        margin-right: 35px;
      }

      .items {
        display: flex;
        flex-direction: row;

        .bulletFact {
          display: flex;

          img {
            width: 28px;
            height: 28px;
          }
          p {
            color: #393939;
            font-family: Circular Std Book, Helvetica, Arial, sans-serif;
            font-size: 18px;
            line-height: 23px;
            padding: 0 40px 0 10px;
            margin: 0;
            strong {
              font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
            }
          }
        }
      }
    }
  }

  .LearnMoreDesktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;

    button {
      border-radius: 40px;
      outline: none;
      border: none;
      cursor: pointer;
      background-color: #00ad66;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      /* v2/green */
      color: #ffffff;
      padding: 0 20px;
    }
    button:hover {
      background-color: #008c54;
    }
  }

  .puppiesNationWideContainer {
    box-sizing: border-box;
    display: contents;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 30px;
    overflow: hidden;
    @include tablet {
      padding: 0 15px;
      width: 100%;
      max-width: unset;
    }
    .puppiesTitle {
      max-width: 1080px;
      width: 100%;
      margin-top: 40px;
      display: grid;
      grid-template-columns: 50px 500px auto;
      align-items: center;
      @include tablet {
        max-width: 708px;
        margin-top: 20px;
        margin-left: 20px;
      }
      @include mobile {
        max-width: 350px;
        margin-left: 0;
        display: flex;
        align-items: center;
      }
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
        @include tablet {
          margin-right: 10px;
        }
      }
      h2 {
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        font-size: 25px;
        line-height: 30px;
        margin: 0;
        color: #393939;
        @include mobile {
          font-size: 20px;
          line-height: 23px;
          width: 100%;
        }
      }
      .title{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-decoration: none;
      }
      .btn{
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        text-decoration: none;
        button {
        
          border-radius: 40px;
          outline: none;
          border: none;
          cursor: pointer;
          background-color: #00ad66;
          font-size: 16px;
          line-height: 30px;
          text-align: center;
          font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
          /* v2/green */
          color: #ffffff;
          padding: 0 20px;
          @include mobile {
            font-size: 14px;
            line-height: 24px;
          }
          
          
        }
        button:hover {
          background-color: #008c54;
        }
      }
    }
    .featuredPuppies {
      width: 100%;
      max-width: 1080px;
      padding: 0 30px;
      overflow: hidden;
      height: 365px;
      position: relative;
      > div {
        > div {
          @include mobile {
            margin-left: 10px;
          }
          > div {
            @include mobile {
              width: auto !important;
            }
          }
        }
      }

      .cardContainer {
        > div {
          margin-top: 21px;
          background-color: transparent;
          position: relative;
        }
      }
    }
    .desktop {
      @include mobile {
        display: none;
      }

      /* Fishing the navigation buttons */
      .swiperButton {
        position: absolute;
        cursor: pointer;
        z-index: 10;
        height: 36px;
        width: 36px;
        background-color: #ffffff;
        border-radius: 50%;
        background-size: 7px;
        background-size: 9px;
        background-color: rgba(255, 255, 255, 0.85);
        border: 0.5px solid rgba(57, 57, 57, 0.3);
        box-shadow: -4px 4px 10px rgba(57, 57, 57, 0.2);
        transition: all 500ms ease-out;
        @include mobile {
          display: none;
        }
      }
      .swiperPrev {
        top: 140px;
        left: 38px;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        background-image: url('/prev-black.svg') !important;
      }
      .swiperButton:hover {
        background-color: #ffffff;
        transform: scale(1.1);
      }
      .swiperNext {
        top: 140px;
        right: 38px;
        background-position: center;
        background-repeat: no-repeat;
        outline: none;
        background-image: url('/next-black.svg') !important;
      }

      // .hide{
      //   top: 140px;
      //   visibility: hidden;
      //   opacity: 0;

      // }
      .hidePrev {
        top: 140px;
        left: 38px;
        opacity: 0;
        transition: all 300ms ease-out;
      }
      .hideNext {
        top: 140px;
        right: 38px;
        opacity: 0;
        transition: all 300ms ease-out;
      }
    }
    .mobile {
      display: none;
      @include mobile {
        display: flex;
        width: 100%;
        height: fit-content;
      }
    }
    .btnMobile {
      width: 100%;
      max-width: 250px;
      text-decoration: none;
      margin: 10px;
      button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 250px;
        height: 44px;
        border-radius: 40px;
        outline: none;
        border: none;
        cursor: pointer;
        background-color: #00ad66;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        color: #ffffff;
        padding: 0 20px;
        .arrow {
          background-image: url('/next-white.svg');
          background-size: cover;
          background-repeat: no-repeat;
          width: 5px;
          height: 12px;
          margin-left: 10px;
        }
      }
      button:hover {
        background-color: #008c54;
      }
    }
  }
}
