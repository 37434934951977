@import "../../scss/base.scss";
.breedListWrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: calc(1085px + 60px);
  padding: 0 30px;
  margin: 0 auto;
  margin-top: 60px;
  @include mobile {
    margin-top: 20px;
    margin-top: 0;
    padding: 0 0 0 15px;
  }
  .article {
    width: 100%;
    @include mobile {
      padding: 0 15px;
      margin-top: 40px;
      width: auto;
    }
    .data {
      display: flex;
      flex-wrap: wrap;
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      line-height: 24px;

      .listItem {
        font-size: 16px;
        width: calc(100% / 4);
        margin: 0;
        @include mobile {
          font-family: Circular Std Book, Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 24px;
          width: calc(100% / 2);
        }
        p {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .link {
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            p {
              color: #00ad66;
            }
          }
        }
        p {
          color: #393939;
        }
      }
      .grey {
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            color: #00ad66;
          }
        }
        p {
          color: rgba(57, 57, 57, 0.6);
          &:hover {
            color: #00ad66;
          }
        }
      }
    }
    .title {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 25px;
      line-height: 32px;
      margin: 0 0 15px 0;
      @include mobile{
        font-size: 18px;
    line-height: 23px;
    margin-top: 20px;
      };
      
      a {
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        font-size: 25px;
        line-height: 32px;
        color: #393939;
        &:hover {
          color: #00ad66;
        }
        @include mobile {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
    .articleContent {
      position: relative;

      .sub {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }
    }
    .view {
      position: relative;
      cursor: pointer;
      
      margin-bottom: 30px;
      p {
        margin-bottom: 0;
        font-weight: 900;
        color: #393939;
        font-size: 16px;
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        @include mobile{
          font-size: 14px;
        }
      }
    }
    .noDogs {
      color: #393939;
      margin: 0 ;
      font-family: Circular Std Book, Helvetica, Arial, sans-serif;
      font-weight: 400;
      font-size: 18px;
      @include mobile {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
.marginBottom {
  margin-bottom: 80px;
}
