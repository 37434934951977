@import "../../scss/base.scss";

.nearbyDogsContainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(1074px + 60px);
  width: 100%;
  padding: 0 30px;
  margin: 50px auto;
  @include mobile {
    padding: 0 15px;
    margin: 10px auto;
  }
  .sectionTitle {
    h2 {
      font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
      font-size: 25px;
      line-height: 32px;
      margin: 0;
      color: #393939;
      @include mobile {
        font-size: 20px;
        line-height: 25px;
      }
    }
  }
  .dogStateContainer {
    margin-top: 25px;

    .title {
      display: flex;
      justify-content: space-between;
      a{
        text-decoration: none;
      }
      h3 {
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        font-size: 20px;
        line-height: 25px;
        color: #393939;
        margin: 0;
        
        @include mobile {
          font-size: 16px;
          line-height: 20px;
          width: 65%;
        }
      }
      .link {
        font-family: Circular Std Bld, Helvetica, Arial, sans-serif;
        font-size: 18px;
        line-height: 30px;
        color: #00ad66;
        text-decoration-line: underline;
        margin: 0;
        @include mobile {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    .nearbyCardsContainer {
      display: flex;
      overflow: hidden;
      grid-column-gap: 19px;
      margin-top: 25px;
      @include mobile {
        grid-column-gap: 15px;
        flex-wrap: wrap;
        > div {
          margin-bottom: 15px;
        }
      }
    }
  }
  .featuredButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 21px;
    cursor: pointer;
    button {
      img {
        height: 15px !important;
        width: 12px !important;
      }
    }
  }
}
