.loader{
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px auto;
  width: 100%;
  
}

.modalSubMenu, .modalSubMenu2{
    box-sizing: border-box;
    width: 650px;
    height: 350px;
    left: 200px;
    bottom: -1px;
    padding: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #a6e8cd;
    
  }
  .modalSubMenu:focus {
    outline: none;
}
.modalSubMenu2{
  height: 270px;
}
.modalSubMenu2:focus{
  outline:  none;
}

  .listBreeds{
    box-sizing: border-box;
    height: 300px;
    overflow-y: auto;
    .sectionList{
      display: flex;
      flex-wrap: wrap;
      .item{ 
        cursor: pointer;
        text-transform: capitalize;
        width: calc(100% /3);
        margin: 0;
     
        font-family: "Circular Std", Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 25px;
    color: #393939;
    text-decoration: none;
      }
  
      .item:hover {
        color: #00ad66;
      }
    } 
  }
  .listBreeds::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  .listBreeds::-webkit-scrollbar:vertical {
    width:5px;
    margin-right: 20px;
  }
  
  .listBreeds::-webkit-scrollbar-button:increment,.listBreeds::-webkit-scrollbar-button {
    display: none;
  } 
  
  .listBreeds::-webkit-scrollbar:horizontal {
    height: 80px;
    width: 20px;
  }
  .listBreeds::-webkit-scrollbar-thumb {
    background-color:  #39393963;
    border-radius: 20px;
    
  }
  
  .listBreeds::-webkit-scrollbar-track {
    border-radius: 10px;  
  }

  .listLocations , .listLocations2{
    box-sizing: border-box;
    height: 300px;
    overflow-y: auto;
    >div{
      color: #393939;
      font-weight: bold;
      font-size: 14px;
      font-family: "Circular Std", Helvetica, Arial, sans-serif;
      margin: 10px 0;
      cursor: pointer;
      display: flex;
    align-items: center;
    >img{
      margin-left: 5px;
    }
    }
    .sectionList{
      display: flex;
      flex-wrap: wrap;
      .item{ 
        cursor: pointer;
        text-transform: capitalize;
        width: calc(100% /3);
        margin: 0;
        a {
          
          font-family: "Circular Std", Helvetica, Arial, sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 25px;
          color: #393939;
          text-decoration: none;
        }
    
        a:hover {
          color: #00ad66;
        }
       }
    }
  }
  .listLocations2{
    height: 230px;
  }
  .listLocations::-webkit-scrollbar, .listLocations2::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  .listLocations::-webkit-scrollbar:vertical, .listLocations2::-webkit-scrollbar:vertical {
    width:5px;
    margin-right: 20px;
  }
  
  .listLocations::-webkit-scrollbar-button:increment,.listLocations::-webkit-scrollbar-button, .listLocations2::-webkit-scrollbar-button:increment,.listLocations::-webkit-scrollbar-button {
    display: none;
  } 
  
  .listLocations::-webkit-scrollbar:horizontal, .listLocations2::-webkit-scrollbar:horizontal {
    height: 80px;
    width: 20px;
  }
  .listLocations::-webkit-scrollbar-thumb, .listLocations2::-webkit-scrollbar-thumb {
    background-color:  #39393963;
    border-radius: 20px;
    
  }
  
  .listLocations::-webkit-scrollbar-track, .listLocations2::-webkit-scrollbar-track {
    border-radius: 10px;  
  }



  .listCategory{
    display: grid;
    grid-template-columns: repeat(6, minmax(50px, 1fr));
    width: 100%;
    max-width: 600px;
    grid-gap: 20px;
    row-gap: 20px;
    justify-content: space-between;
    a{
      display: flex;
      flex-direction: column;
      align-items: center;
      span img{
      box-sizing: border-box;
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          cursor: pointer;
          width: 165px ;
          height: 165px;
          aspect-ratio: attr(width) / attr(height);
     }
    }
 a:hover{
  div{
    color: #00ad66;
  }
 span img{
 
    border-radius: 80px;
    border:4px solid #00ad66 !important ;
  }
 }
    div{
      color: #393939;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      font-family: "Circular Std", Helvetica, Arial, sans-serif;
      text-align: center;
      margin-top: 15px;
    }
  
    img{
      display: flex;
      align-items: center;
    }
    
  }