@import '../../scss/base.scss';

.categories {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: Circular Std Book, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #393939;
  cursor: pointer;

  h2 {
    width: 100%;
    margin: 0 0 30px ;
    line-height: 31.62px;
    @include title;
    @include mobile {
      margin-bottom: 20px;
      font-size: 25px;
      line-height: 32px;
    }
    @media (max-width: 460px) {
      font-size: 20px;
      line-height: 26px;
    }
  }

  .box {
    .category {
      display: flex;
      flex-direction: column;

      .image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;
      }

      .footer {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 15px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        h4 {
          margin: 0px;
        }
      }
    }

    &.desktop {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 10px;
      @include mobile {
        display: none;
      }
    }

    &.mobile {
      display: none;
      @include mobile {
        display: flex;
        width: calc(100% + 30px);
       margin-left: -15px;
        >div{
          >div{
            >div{
              max-width: 210px;
              min-width: 210px;
            }
            >div:last-child{
              margin-right: 15px;
            }
            >div:first-child{
              margin-left: 15px;
            }
          }
        }
      }
    }
  }
  button {
    width: 250px;
    margin: 0 auto;

    @include mobile {
      display: none;
    }

    div {
      font-size: 18px;
    }

    > img {
      width: 15px;
      margin-left: 10px;
    }
  }
}
.categories[id] {
  scroll-margin-top: 70px;
}
